.scorecard {
  margin-top: 40px;
}

.graphTransition {
  text-align: left;
  padding: 5px;
  transition: height 0.3s ease-in-out;
}

.expand-button {
  background-color: rgb(233, 228, 232);
  box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 100px;
  right: calc(50% + 15px);
  bottom: -15px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}

.variation-accordion-item-header {
  width: 100%;
  h2 {
    margin: 0;
    font-size: 20px;
  }

  p {
    span {
      font-weight: bold;
    }
  }

  .winner-loser-icon {
    font-size: 40px;
    margin: -40px -60px 0 0;
  }
}

.business-impact {
  color: black;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
}

.variation-paper-result {
  display: flex;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.experiment-list-overflow-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* show 3 lines of text */
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-range-picker {
  position: fixed;
  top: 365px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calender-icon {
  cursor: pointer;
  transition: color 0.2s ease;
}
.calender-icon:hover {
  color: #1976d2;
}

.experiment-list-row:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
  cursor: pointer;
}

.pulsate:not(.Mui-disabled) {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.drag-space {
  margin: 12px;
  cursor: pointer;
  padding-top: 10px;
  border-radius: 10px;
  color: #777;
  z-index: 1;

  &:hover {
    color: #000;
    background-color: #eee;
  }

  &.drag-disable {
    opacity: 0.2;
    pointer-events: none;
  }
}

.has-live-rules-legend-overide {
  legend {
    width: 83px;
  }
}

.touchpoint-legend-overide {
  legend {
    width: 71px;
  }
}

.overflow-fade {
  position: absolute;
  width: 30px;
  height: 100%;
  right: 0;
  background: linear-gradient(to right, rgb(255 255 255 / 0%), #ffffff);
}

.active-filter {
  box-shadow: 0 8px 8px -2px #b8e18f;
}

.rule_id {
  display: none;
}

.MuiDialog-container .rule_id {
  display: table-cell;
}

.department-filter-disabled > .Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.rule-is-mab {
  border-left: 5px solid rgba(0, 0, 0, 0.12);
}

.rule-is-live {
  border-right: 5px solid rgb(127, 189, 49);
}

.hidden {
  display: none;
}

.metrics_error {
  margin-bottom: 10px;
}

.custom-metrics-form > div {
  min-height: 95px;  

}
.custom-metrics-form > div > span {
  font-style: italic;
  font-size: 0.65rem;
}

.apexcharts-series {
  cursor: pointer;
}

.watchtower-tooltip.dark {
  background-color: rgb(30, 30, 30);
  color: #fff;
}

.snackbar-state-error {
  white-space: pre-line;
  #notistack-snackbar{
    display: inline-block;
    &::first-line {
        font-weight: bold;
      }
  }
  
}

.clashinfo-list {
  list-style-type: disc;
  margin-left: 1em;
    text-align: left;
  li {
    display: list-item;
    span {
        font-size: 0.8rem;
        line-height: 0.8rem;
      }
  }
}
.has-match {
  background-color: rgb(255, 244, 229);
}

.darkmode .has-match {
  background-color: rgb(118 73 13);
}

.history-table {
  th {
    padding-right: 0;
  }
  .MuiFormControl-root {
    text-align: left;
  }
}

.history-dialog{
  .jsoneditor-menu, .jsoneditor-navigation-bar {
    display:none;
  }
}

.MuiTooltip-tooltip.no-max-width {
  max-width: none;
}